<template>
	<div class="integralmall">
		<div class="index-top">
			<div class="index-title">
				<div class="title">积分兑换</div>
			</div>
			<div class="index-prompt">
				<div class="text">当前可用积分：<span style="color:#67C23A;font-size:18px">200</span></div>
			</div>
		</div>
		<div class="malllist">
			<div class="dan" v-for="(item,key) in malllist" :key="key" @click="toPath(item)">
				<div class="image">
					<!-- <span>兑换次数 3/10</span> -->
					<img :src="item.imgurl" alt="">
				</div>
				<div class="text">
					<div class="title">
						<p class="name">{{item.title}}</p>
					</div>
					<div class="duihuan">
						<el-button type="primary">兑换</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data () {
		return {
			malllist:[
				{
					title:'素材配音',
					imgurl:'https://erlangcha.oss-cn-beijing.aliyuncs.com/usergongju/jifen1.jpg',
					path:'/voice'
				},
				{
					title:'视频实拍',
					imgurl:'https://erlangcha.oss-cn-beijing.aliyuncs.com/usergongju/jifen2.jpg',
					path:'/tofilm'
				}
			]
		}
	},

	mounted(){
	},

	methods: {
		toPath(item){
			this.$router.push(item.path)
		}
		// 测试用
		// onceshi(){
		// 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
		// 		console.log(123)
		// 		if(res.code == '200'){

		// 		}
		// 	})
		// },
	}
}
</script>

<style lang="scss" scoped>
.integralmall{
	min-height: calc(100vh - 200px);
	.malllist{
		display: flex;
		flex-wrap: wrap;
		width: calc(100% + 30px);
		margin-left: -30px;
		margin-top: 20px;
		.dan{
			box-shadow: 0 2px 12px 0 #ededed;
			margin-left: 30px;
			margin-bottom: 30px;
			width: calc(20% - 30px);
			min-width: 300px;
			height: 400px;
			cursor: pointer;
			overflow: hidden;
			&:hover{
				opacity: .9;
				img{
					transform: scale(1.1);
					transition: .5s;
				}
			}
			.image{
				width: 100%;
				height: 330px;
				overflow: hidden;
				position: relative;
				span{
					position: absolute;
					right: 0;
					top: 0;
					background: rgba(0,0,0,.8);
					padding: 5px;
					color: rgba(255,255,255,.9);
					font-size: 12px;
					z-index: 1;
				}
				img{
					transition: .5s;
				}
			}
			.text{
				padding: 15px 20px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				
				.title{
					.name{
						font-size: 16px;
					}
					.num{
						margin-top: 5px;
						color: #f00;
						font-size: 26px;
						span{
							margin-left: 5px;
						}
					}
				}
			}
		}
	}
}
</style>
